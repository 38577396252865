import styled from "styled-components"

const EuProjectStyled = styled.div`
  .logotype__container {
    padding: 60px 0 80px;
    max-width: 840px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logotype__item {
    width: calc(100% / 3);
    &:nth-of-type(2) {
      text-align: center;
    }
    &:nth-of-type(3) {
      text-align: right;
    }
  }
  .projects__item {
    max-width: 960px;
    &:last-of-type {
      .projects__item__description > div {
        padding-bottom: 0px;
      }
    }
  }
  .projects__container {
    padding-top: 20px;
    padding-bottom: 100px;
  }
  .projects__item__title {
    cursor: pointer;
    font-size: 17px;
    display: flex;
    align-items: flex-start;
    i {
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      display: block;
    }
    span {
      &:first-child {
        width: 200px;
        display: flex;
        align-items: flex-end;
        font-weight: 300;
        &:after {
          content: "";
          margin: 0 15px;
          width: 100%;
          display: block;
          height: 1px;
          background: #707070;
          margin-bottom: 8px;
        }
      }
      &:nth-of-type(2) {
        max-width: calc(100% - 200px);
        width: 100%;
      }
    }
  }
  .projects__item__description {
    padding-left: 160px;
    & > div {
      padding-bottom: 100px;
    }
  }
  .projects__item__subtitle {
    font-weight: 300;
    &:after {
      margin-top: 20px;
      content: "";
      display: block;
      width: 65px;
      height: 1px;
      background: #000000;
    }
  }
  p {
    margin: 0;
    padding: 10px 0;
  }
`

export default EuProjectStyled
